.main-circle {
  background: #bec4de;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  /* width: 35px;
  height: 35px; */
  border: 2px solid #ffffff;
  position: relative;
  margin: 0px;
  padding: 0px;
  transition: 0.5s;
}

.inner-text {
  margin: 0px;
  color: #1c1e2b;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  /* padding-top: 4px; */
  font: normal normal bold 0.75rem/16px Poppins;
}

.main-circle:hover {
  margin-top: -5px;
  transition: 0.1s ease-in-out;
  z-index: 100;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 100px;
  color: #1c1e2b;
  border-radius: 3px;
  text-align: center;
  font: normal normal normal .75rem/16px Poppins;
  background: #bec4de 0% 0% no-repeat padding-box;
}

.hover-text:hover .tooltip {
  visibility: visible;
}

#bottom {
  top: 35px;
  left: -157%;
}

.hover-text {
  position: relative;
  text-align: center;
}