.robot__teleop__details {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 40%; */
    background: #292F40 0% 0% no-repeat padding-box;
    border: 3px solid #735AB6;
    border-radius: 7px;
    padding: 5px;
    transition: all 0.3s ease-in-out;
}

.robot__teleop__details__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}

.robot__teleop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 59%;
    height: 40px;
    background: #735AB6 0% 0% no-repeat padding-box;
    border: 2px solid #735AB6;
    border-radius: 5px;
    text-align: center;
    font: normal normal bold .75rem/16px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}

.robot__teleop__ack {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 29%;
    height: 40px;
    border: 2px solid #E96C67;
    border-radius: 5px;
    text-align: center;
    font: normal normal bold .70rem/16px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}

.robot__teleop__location {
    text-align: left;
    font: normal normal normal .75rem/16px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.robot__order__id {
    text-align: left;
    font: normal normal bold .75rem/16px Poppins;
    letter-spacing: 0px;
    margin-bottom: 5px;
    color: #FFFFFF;
    opacity: 1;
}

.robot__actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: flex-end;
    margin-bottom: 10px;
}

.robot__action {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #88CD6F 0% 0% no-repeat padding-box;
    border-radius: 5px;
    height: 35px;
    width: 40%;
    text-align: center;
    font: normal normal bold .75rem/16px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.teleop__watching {
    display: flex;
    align-items: flex-end;
    text-align: center;
    font: normal normal normal .70rem/16px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.teleop__watching strong {
    margin: 0 3px;
    text-align: center;
    font: normal normal normal .75rem/16px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.watching__icon {
    color: #BEC4DE;
    width: 15px;
    height: 15px;
}

.users__info__panel {
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #bec4de 0% 0% no-repeat padding-box;
    border-radius: 7px;
}

.users__info {
    width: 100%;
    height: 60%;
    min-width: 40px;
    background: #292f40 0% 0% no-repeat padding-box;
    border: 1px solid #bec4de;
    border-radius: 7px;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.game__controller__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.current__user {
    text-align: center;
    font: normal normal bold .70rem/16px Poppins;
    letter-spacing: 0px;
    color: #BEC4DE;
    opacity: 1;
}

.circle__icons {
    display: flex;
    flex-direction: row;
}

.circle__icon:not(:first-child) {
    margin-left: -6px;
}

.unlock__btn {
    width: 100%;
    min-width: 30px;
    height: 40%;
    background: #bec4de 0% 0% no-repeat padding-box;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font: normal normal bold 18px/16px Poppins;
    letter-spacing: 0px;
    color: #292F40;
    cursor: pointer;
    opacity: 1;
}

.control__btn__text {
    text-align: center;
    font: normal normal bold .75rem/16px Poppins;
    letter-spacing: 0px;
    color: #8d91a3;
}

.controller__status {
    text-align: left;
    font: normal normal bold .65rem/16px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
}

.game__controller__status img {
    width: 30px;
    height: 30px;
}

@media screen and (max-width: 1136px) {
    .robot__teleop {
        font-size: .65rem;
    }

    .robot__teleop__ack {
        font-size: .55rem;
    }

    .robot__teleop__location,
    .robot__order__id {
        font-size: 0.60rem;
    }

    .robot__action {
        font-size: 0.60rem;
    }

    /* .watching__icon {
        width: 15px;
        height: 15px;
    } */

    .teleop__watching {
        font-size: .60rem;
    }

    .teleop__watching strong {
        font-size: 0.70rem;
    }

    .robot__action {
        padding: 0px 10px;
    }

    .game__controller__status img {
        width: 20px;
        height: 20px;
    }

    .controller__status {
        font-size: 0.55rem;
    }

    .current__user {
        font-size: 0.60rem;
    }

    .main-circle {
        width: 20px;
        height: 20px;
    }

    .users__info {
        padding: 5px;
    }

    .inner-text {
        font-size: 0.50rem;
    }

    .unlock__btn {
        font-size: 0.80rem;
    }
}