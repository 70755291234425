.teleop-p{
    display: flex;
    flex-direction: row;
    background-color: #E96C67;
    color: #FFFFFF;
    border-radius: 4px;
    font-size: 11px;
    padding: 1.2px 9px;
    font-weight: 100;
}

@media screen and (max-width: 327px) {
  .teleop-p {
    font-size: 5px;
  }
}
@media screen and (max-width: 269px) {
  .inner-card-items {
    display: grid !important;
    gap: 2rem;
  }
  .card-outer-items{
    display: grid !important;
    gap: 1rem
  }
}

.card {
  top: 54px;
  width: 100%;
  background: #292f40 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  /* position: absolute; */
  /* padding: 2rem; */
}

.card-items {
  padding: 26px;
}

.card-outer-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-item-1 {
  width: 81px;
  height: 20px;
  text-align: left;
  font: normal normal bold 14px/16px Poppins;
  letter-spacing: 0px;
  /* color: #e96c67; */
  opacity: 1;
  display: flex;
  gap: 3.5px;
  align-items: center;
}

.card-item-2 {
  top: 213px;
  left: 81px;
  width: 65px;
  height: 20px;
  text-align: left;
  font: normal normal normal 14px/16px Poppins;
  letter-spacing: 0px;
  color: #71cf62;
  opacity: 1;
}

.card-item-3 {
  width: 60px;
  height: 25px;
  opacity: 1;
  color: white;
  display: flex;
  gap: 11px;
  align-items: center;
  padding-top: 4px;
}

.cards {
    width: 438px;
    top: 3rem;
    left: 4rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.card-outer-right {
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
}

.card-outer-left {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.card-white-line {
  border: 1px solid #bec4de;
  opacity: 1;
  margin-top: 19px;
  margin-bottom: 19px;
}

.card-item-right {
    display: flex;
    /* gap: 5px; */
    align-items: center;
    color: white;
    flex-direction: column;
    justify-content: space-between;
}

.card-item-right-1{
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: flex-end;
}

.signal-icon {
  font-size: 23px;
}

.play-pause-icon {
  font-size: 18px;
}

.inner-card-items {
  display: flex;
  justify-content: space-between;
}

.circle-icons{
    display: flex;
    flex-direction: row;
}

.circle-icon:not(:first-child){
  margin-left: -6px;
}

.location-h3{
  font: normal normal bold 18px/16px Poppins;
  margin: 0px;
  letter-spacing: 1px;
}

.card-total-robots{
  font: normal normal bold 30px/16px Poppins;
  margin: 0px;
}

.battery-icon{
  display: flex;
    flex-direction: column;
    align-items: center;
}


@media only screen and (max-width: 976px) {
  .cards {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  .card-item-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}

