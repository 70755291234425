* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: inherit;
}

.main__controls {
  background-color: #1c1e2b;
  padding: 10px;
  scroll-behavior: smooth;
  height: 100vh;
  width: 100vw;
  /* height: max-content; */
  display: flex;
  flex-direction: column;
}

.camera {
  color: white;
  top: 0px;
  width: 629px;
  height: 100%;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cameras {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30vh;
}

.h1-text-upper-camera {
  top: 111px;
  left: 135px;
  text-align: center;
  font: normal normal bold 28px/16px Poppins;
  letter-spacing: 0px;
  color: #bec4de;
  opacity: 1;
  margin: 0px;
}

.robot-controll-id {
  position: fixed;
  color: rgb(0, 0, 0);
  background-color: #8e93a6;
  padding: 9px;
  display: flex;
  align-items: center;
  gap: 14px;
  border-bottom-right-radius: 7px;
  z-index: 100;
  width: 100%;
}

.main-contents {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-panel {
  /* top: 711px;
  left: 99px; */
  width: 226px;
  height: 47px;
  background: #292f40 0% 0% no-repeat padding-box;
  border: 1px solid #bec4de;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-block: 4px;
  padding-inline: 11px;
  margin-left: 22px;
}

.top-status-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  padding: 1rem 1rem;
  justify-content: space-between;
}

.other-users {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.game-controller-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.current-user {
  color: white;
}

.watching {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-top: 6px;
}

.watching-total {
  font: normal normal bold 30px/16px Poppins;
}

.controll-button {
  width: 129px;
  height: 57px;
  background: #bec4de 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}

.controll-button-text {
  top: 726px;
  left: 352px;
  width: 92px;
  height: 20px;
  /* UI Properties */
  text-align: center;
  font: normal normal bold 14px/16px Poppins;
  letter-spacing: 0px;
  color: #8d91a3;
}

.status-panel {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  gap: 30px;
}

.inner-stuff {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.battery-status {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 21px;
}

.speed-status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.weather-status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.time-status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.battery-status-icon {
  font-size: 52px;
}

.switch-main {
  border: 1px solid white;
  background: transparent;
}

/* :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked {
  background: rgb(39, 211, 91);
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: transparent;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-switch .ant-switch-handle {
  position: absolute;
  top: 1px;
  inset-inline-start: 2px;
  width: 10px;
  height: 10px;
  transition: all 0.2s ease-in-out;
} */

.on-off-switch {
  color: white;
  display: flex;
  align-items: center;
  gap: 14px;
}

.unlock-p {
  font-size: 19px;
  padding-top: 4px;
}

.left-status-bar {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.cam-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.main__container {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr 1.5fr;
  gap: 10px 10px;
  position: relative;
  grid-auto-flow: row;
  grid-template-areas:
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "teleop__details video__stream video__stream"
    "teleop__details video__stream video__stream";
  transition: all 0.4s ease-in-out;
}

.main__container__toggle {
  display: grid;
  height: 100%;
  grid-template-columns: 20px auto;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr 1.5fr;
  gap: 10px 10px;
  position: relative;
  grid-auto-flow: row;
  grid-template-areas:
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "map video__stream video__stream"
    "teleop__details video__stream video__stream"
    "teleop__details video__stream video__stream";
}

.video__stream {
  grid-area: video__stream;
  background-color: #292F40;
  border-radius: 7px;
  /* justify-content: end;
  display: flex; */
}

.map {
  display: flex;
  grid-area: map;
  border-radius: 7px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.teleop__details {
  grid-area: teleop__details;
  background-color: #292F40;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #292F40 0% 0% no-repeat padding-box;
  border: 3px solid #735AB6;
  border-radius: 7px;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}

.landscape__popup__container {
  display: none;
}

.MuiBox-root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none !important;
  background-color: #2b2a44 !important;
  color: white;
  border-radius: 7px;
}

.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 91%);
}

@media (orientation: portrait) {
  .landscape__popup__container {
    display: flex;
  }
}

@media screen and (max-width: 768px) and (orientation: landscape) and (min-device-aspect-ratio: 2/1) {
  .landscape__popup__container {
    display: none;
  }
}