.components{
    display: flex;
    color: white;
}

.joystick-div{
    width: 50%;
}

.form-div{
    width: 50%;
}