.front__main__camera {
    color: white;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    opacity: 1;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    align-items: flex-start;
}

.cam__video {
    height: 100%;
    /* max-width: fit-content; */
    max-height: fit-content;
    width: 100%;
    object-position: top;
    border-radius: 10px;
}

.unlock__robot__controls {
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unlock__robot__controls__text {
    text-transform: uppercase;
    font: normal normal bold 0.65rem/16px Poppins;
    letter-spacing: 0px;
    color: #BEC4DE;
    opacity: 1;
}

.unlock__robot__controls__container {
    padding-inline: 10px;
    display: flex;
    width: 100%;
    height: 4%;
    border-radius: 7px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-size: 0.75rem;
}