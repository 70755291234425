.nav-main {
  /* width: 100%; */
  height: 73px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  display: grid;
  align-items: center;
  /* min-width: 284px; */
}

.nav-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 4rem;
}

.white-line {
  border: 1px solid #bec4de;
  opacity: 1;
}

.nav-right {
  display: flex;
  flex-direction: row;
  opacity: 1;
  gap: 1rem;
}

.nav-right-1 {
  display: flex;
  flex-direction: row;
}

.nav-right-1-info {
  color: #ffffff;
  opacity: 1;
  padding-left: 1rem;
}

.nav-right-1-logo {
  /* background: transparent url("../../assets/user.png") 0% 0% no-repeat
    padding-box; */
  opacity: 1;
  display: flex;
  align-items: center;
}

.nav-right-2-logo {
  /* background: transparent url("../../assets/switch.png") 0% 0% no-repeat
    padding-box; */
  opacity: 1;
  display: flex;
  align-items: center;
}

.user-name {
  margin: 0px;
  font: normal normal bold 14px/16px Poppins;
  letter-spacing: 1px;
}

.user-email {
  margin: 0px;
}

@media only screen and (max-width: 976px) {
  .nav-items {
    padding-inline: 17px;
  }
}

/* @media only screen and (max-width: 500px) {
  .nav-right {
    display: none;
  }
} */
