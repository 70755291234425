
.cus-battery-icon {
    display: flex;
    border: 1.5px solid white;
    width: 21px;
    height: 11px;
    border-radius: 4px;
    padding-inline: 2px;
    padding-block: 2px;
    position: relative;
    align-items: center;
}

.cus-battery-part {
    height: 6px;
    width: 3.5px;
    background: white;
    border-top-right-radius: 100rem;
    border-bottom-right-radius: 100rem;
    position: absolute;
    left: 18.5px;
}

.cus-inner-battery {
    background: #88cd6f;
    height: 100%;
    /* width: 30%; */
    border-radius: 2px;
    align-items: center;
    justify-content: center;
}