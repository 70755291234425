input {
    width: 100%;
    height: 100px;
    padding: 20px;
    font-size: 20px;
    border: none;
    box-sizing: border-box;
  }
  
  .simple-keyboard {
    max-width: 100%;
  }
  
.hg-rows{
    color: #9fa4be;
    background-color: #1c1e2b;
    /* border: 2px solid #9fa4be; */
    padding: 3px;
}

.hg-theme-default {
    padding: 0px;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;
    overflow: hidden;
    touch-action: manipulation;
    user-select: none;
    width: 100%;
    /* margin: auto; */
}

.hg-theme-default .hg-button {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px rgba(0,0,0,.3);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 5px;
    border: 2px solid #9fa4be;
    background: transparent;
}

.hg-theme-default .hg-button.hg-activeButton{
    background: greenyellow;
}

.keyboardContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 2px solid #9fa4be;
}

.numPad{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.controlArrows{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: normal;
}

.hg-theme-default .hg-button.hg-button-numpadadd, .hg-theme-default .hg-button.hg-button-numpadenter {
    height: 108px;
  
}
