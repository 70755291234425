.home-main {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  padding-inline: 4rem;
  padding-block: 2rem;
}

@media only screen and (max-width: 976px) {
  .home-main {
    display: flex;
    flex-direction: column;
    padding-inline: 17px;
  }
}
