@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

:root {
  --sidebarmax: 35vw
}

.main-app {
  background: #1c1e2b 0% 0% no-repeat padding-box;
  /* min-width: 344px; */
  min-height: 100vh;
}

@media only screen and (max-width: 163px) {
  .main-app {
    position: absolute;
  }
}

/* @media only screen and (max-width: 299px) {
  .App {
   position: fixed;
  }
} */

h1, h2, h3, h4, p {
  font: normal normal normal 14px/16px Poppins;
  cursor: default;
  margin: 0px;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}  */