.robot__map {
    width: 100%;
    height: 100%;
    opacity: 1;
    height: 60%;
    border-radius: 7px;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}

.sidebar__minimize__btn {
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    width: 40px;
    position: absolute;
    top: calc((100vh - 10%) / 2);
    height: 40px;
    border-radius: 20px;
    background: #735AB6;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    opacity: 0.5;
    /* visibility: hidden; */
}

.sidebar__minimize__btn:hover {
    opacity: 1;
}

.teleop__details:hover .sidebar__minimize__btn,
.map:hover .sidebar__minimize__btn {
    visibility: visible !important;
    /* opacity: 1; */
}

/* .sidebar__minimize__btn:hover {
    visibility: visible;
  } */

/* .sidebar__container:hover .sidebar__minimize__btn {
    background-color: #735AB6;
  } */

.sidebar__minimize__btn__icon {
    font-size: 24px;
}