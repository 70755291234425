/* Navbar */
header {
    border-radius: 7px;
    width: 100%;
    height: 45px;
    margin-bottom: 10px;
    background: #292f40 0% 0% no-repeat padding-box;
}

nav {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
}

.nav__left {
    display: flex;
    align-items: center;
    width: 19%;
}

.back__btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.back__btn__icon {
    color: #bec4de;
    cursor: pointer;
}

.robot__detail {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: column; */
}

.robot__id__container {
    min-width: 150px;
    display: flex;
    flex-direction: column;
}

.robot__id {
    text-align: left;
    font: normal normal bolder 14px/16px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}

.robot__date {
    text-align: left;
    font: normal normal normal 10px/16px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0.72;
}

.robot__battery__details {
    display: flex;
    align-items: center;
}

.robot__status {
    text-align: right;
    font: normal normal bold 11px/16px Poppins;
    letter-spacing: 0px;
    color: #e96c67;
    opacity: 1;
}

.robot__battery__percentage {
    text-align: right;
    font: normal normal bold 12px/16px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}

.robot__battery__logo {
    margin-left: 5px;
}

.nav__center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
}

/* .robot__speed__details {
    min-width: 50px;
}

.robot__total__distance__details {
    min-width: 150px;
} */

.robot__speed__details,
.robot__ring__light__details,
.robot__head__light__details,
.robot__total__distance__details,
.robot__stream__client__details {
    display: flex;
    flex-direction: column;
    margin-inline: 4%;
}

.robot__speed__text,
.robot__ring__light__text,
.robot__head__light__text,
.robot__total__distance__text,
.robot__stream__client__text {
    text-transform: uppercase;
    text-align: left;
    font: normal normal bold 0.65rem/16px Poppins;
    letter-spacing: 0px;
    color: #BEC4DE;
    opacity: 1;
}

.robot__speed,
.robot__total__distance {
    display: flex;
    align-items: center;
    text-align: left;
    font: normal normal normal 0.75rem/16px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.robot__ring__light__switch,
.robot__head__light__switch,
.robot__stream__client__switch {
    color: white;
    display: flex;
    align-items: center;
    gap: 14px;
}

.robot__ring__light__switch__main,
.robot__head__light__switch__main,
.robot__unlock__switch__main,
.robot__stream__client__switch__main {
    border: 1px solid white;
    background: transparent;
    width: 33px;
    height: 14px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-switch {
    min-width: 40px;
    height: 14px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-switch.ant-switch-checked {
    background: #735AB6;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: transparent;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-switch .ant-switch-handle {
    position: absolute;
    top: 1px;
    inset-inline-start: 1px;
    width: 10px;
    height: 10px;
    transition: all 0.2s ease-in-out;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 12px);
}

.nav__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
}

.reset__diagnostics__btn {
    min-width: 120px;
    width: 55%;
    height: 100%;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #BBC1DC;
    border-radius: 5px;
    text-align: left;
    font: normal normal normal 0.75rem/16px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    cursor: pointer;
    margin-inline: 3%;
}

.robot__e__stop {
    min-width: 55px;
    width: 25%;
    height: 100%;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E96C67 0% 0% no-repeat padding-box;
    border-radius: 5px;
    text-align: left;
    font: normal normal bold 0.75rem/16px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    cursor: pointer;
}

.robot__expandable__options {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 5px; */
    font-size: 22px;
    height: 100%;
    color: #BBC1DC;
    opacity: 1;
    cursor: pointer;
}

.menu__extra__options {
    font: normal normal normal 0.75rem/16px Poppins !important;
    letter-spacing: 0px;
    height: 35px;
    display: flex;
    width: 100%;
    justify-content: space-between !important;
    align-items: center;
}

.menu__extra__options:nth-child(1) {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.menu__extra__options:nth-last-child(1) {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.menu__extra__options:hover {
    background-color: #292f40 !important;
}

.menu__extra__responsive {
    display: none !important;
}

.nav__right__extra__options {
    display: none !important;
}

@media screen and (max-width: 1275px) {

    .robot__head__light__details,
    .robot__ring__light__details {
        display: none;
    }

    .menu__extra__responsive {
        display: flex !important;
    }
}

@media screen and (max-width: 768px) {
    .robot__date {
        font-size: 9px;
    }

    .reset__diagnostics__btn,
    .robot__e__stop {
        display: none !important;
    }

    .nav__right__extra__options {
        display: flex !important;
    }

    .nav__left {
        width: 29%;
    }

    .nav__center {
        justify-content: flex-end;
        width: 70%;
    }

    .nav__right {
        width: 1%;
    }
}

/* @media screen and (max-width: 425px) {
    .nav__right {
        display: none;
    }
} fit-content max-height*/